export default {
  quiz: {
    buttonStart: 'start',
    buttonAnswer: 'answer',
    buttonEnd: 'end quiz',
    buttonRetake: 'retake quiz',
    buttonPrevResults: 'previous results',
    notAvailableStart: 'This quiz is only available from',
    notAvailableEnd: 'to',
    score: 'score',
    points: 'points',
    failed: 'Failed',
    success: 'Passed',
    finished: 'Finished',
    prevResultIntro: 'Last results',
    prevResultEmpty: 'No previous results',
    closed: 'Test is closed. Please notify teacher to reopen it',
  }
};
