import { types } from './types';
import { pageApi, taskApi, quizApi } from '../../constants/urls';

const getOptions = (method, data) => {
  return {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      token: JSON.parse(localStorage.getItem('token')),
    },
    body: JSON.stringify({ method, data }),
  };
}

const getPageById = (id) => async (dispatch) => {
  try {
    const options = getOptions('findById', { id });
    const url = pageApi();
    const response = await fetch(url, options);
    const data = await response.json();

    if (response.status !== 200) {
      throw new Error(data.errMsg);
    }

    await dispatch({
      type: types.GET_PAGE_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.GET_PAGE_DATA_FAIL,
      payload: error.message,
      error: true,
    });
  }
};

const answerQuestion = (answerData) => async (dispatch) => {
  try {
    const options = getOptions('answer', answerData);
    const url = taskApi();
    const response = await fetch(url, options);
    const data = await response.json();

    if (response.status !== 200) {
      throw new Error(data.errMsg);
    }

    await dispatch({
      type: types.ANSWER_QUIZ_QUESTION_SUCCESS,
      payload: data.data.taskResult,
    });
  } catch (error) {
    dispatch({
      type: types.ANSWER_QUIZ_QUESTION_FAIL,
      payload: error.message,
      error: true,
    });
  }
};

const quizComplete = (pageId) => async (dispatch) => {
  try {
    const options = getOptions('complete', { pageId });
    const url = quizApi();
    const response = await fetch(url, options);
    const data = await response.json();

    if (response.status !== 200) {
      throw new Error(data.errMsg);
    }

    await dispatch({
      type: types.QUIZ_COMPLETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.QUIZ_COMPLETE_FAIL,
      payload: error.message,
      error: true,
    });
  }
};

const setQuizStep = step => ({
  type: types.SET_QUIZ_STEP,
  payload: step,
});

const setTimerStartDate = date => ({
  type: types.SET_TIMER_DATE,
  payload: date
})

export default { getPageById, answerQuestion, quizComplete, setQuizStep, setTimerStartDate };
