import { types, steps } from '../../actions/quiz/types';
import { snackActions } from '../../helpers/snackbarHelper';

const initialState = {
  step: steps.QUIZ_INTRO
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PAGE_DATA_SUCCESS:
      const { data } = action.payload;
      const prevResults = data.userdata ? data.userdata.pages[data.id] : null;
      const quizData = data.pageRows.map(q => {
        const { params } = q.pageModules[0].data;

        return {
          pageModuleId: q.pageModules[0].id,
          taskId: params.task.taskId,
          task: params.task,
          taskResults: params.taskResults,
          type: params.task.type,
          answered: false
        }
      });

      return {
        ...state,
        prevResults,
        quizData,
        userdata: data.userdata
      };
    case types.GET_PAGE_DATA_FAIL:
      snackActions.error(action.payload)
      return state;
    case types.ANSWER_QUIZ_QUESTION_SUCCESS:
      const taskResult = action.payload;
      const updatedQuizData = state.quizData.map(q => {
        if (q.taskId !== taskResult.task_id) return q;

        return {
          ...q,
          answered: true,
          taskResults: [
            ...q.taskResults,
            taskResult
          ]
        }
      });

      return {
        ...state,
        quizData: updatedQuizData
      };
    case types.ANSWER_QUIZ_QUESTION_FAIL:
      snackActions.error(action.payload);
      return state;
    case types.QUIZ_TIMEOUT:
      return {
        ...state,
        step: steps.QUIZ_RESULT,
      }
    case types.QUIZ_COMPLETE_SUCCESS:
      return {
        ...state,
        results: action.payload.data,
        step: steps.QUIZ_RESULT,
        quizData: state.quizData.map(q => ({
          ...q,
          answered: false,
        })),
      };
    case types.QUIZ_COMPLETE_FAIL:
      snackActions.error(action.payload);
      return state;
    case types.SET_QUIZ_STEP:
      return {
        ...state,
        step: action.payload
      };
    case types.SET_TIMER_DATE:
      return {
        ...state,
        startDate: action.payload
      };
    default:
      return state;
  }
};
