export default {
  quiz: {
    buttonStart: 'starta',
    buttonAnswer: 'svara',
    buttonEnd: 'Avsluta Quiz',
    buttonRetake: 'Gör om Quizen',
    buttonPrevResults: 'Tidigare resultat',
    notAvailableStart: 'Det här quizet är endast tillgängligt från',
    notAvailableEnd: 'till',
    score: 'poäng',
    points: 'poäng',
    failed: 'Icke godkänd',
    success: 'Godkänd',
    finished: 'Sluttid',
    prevResultIntro: 'Senaste resultatet',
    prevResultEmpty: 'Det finns inga resultat sedan tidigare',
    closed: 'Testet är stängt. Meddela läraren att öppna den igen',
  }
};
