import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { steps } from '../../actions/quiz/types';
import StartView from './StartView';
import ResultView from './ResultView';
import Quiztake from './Quiztake';
import { getTime } from 'date-fns';

const Quiz = ({
  quizData,
  userdata,
  results,
  step,
  course,
  handleQuizStart,
  handleQuizAnswer,
  handleQuizComplete,
  handleTimerStartDate,
  startDate,
}) => {
  const theme = useTheme();
  const {
    QUIZ_INTRO,
    QUIZ_MAIN,
    QUIZ_RESULT,
  } = steps;

  const startQuizAndSetTimer = () => {
    handleQuizStart(QUIZ_MAIN);
    handleTimerStartDate(getTime(Date.now()));
  }

  if (step === QUIZ_INTRO) {
    return (
      <StartView
        quizData={quizData}
        settings={course.quizSettings}
        handleQuizStart={startQuizAndSetTimer}
        userdata={userdata}
      />
    );
  }

  if (step === QUIZ_RESULT) {
    return (
      <ResultView
        quizData={quizData}
        results={results}
        settings={course.quizSettings}
        handleQuizStart={() => handleQuizStart(QUIZ_INTRO)}
      />
    );
  }

  return (
    <Quiztake
      quizData={quizData}
      pageId={course.pageId}
      themeColorPrimary={theme.palette.primary}
      handleQuizAnswer={handleQuizAnswer}
      handleQuizComplete={handleQuizComplete}
      startDate={startDate}
      timer={course.timer}
      isTimer={course.isTimer}
    />
  );
};

export default Quiz;
