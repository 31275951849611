import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import PaginationButton from './Pagination';
import QuestionSingle from './QuestionSingle';
import QuestionMulti from './QuestionMulti';
import Countdown, { zeroPad } from 'react-countdown';
import { useDispatch } from 'react-redux';
import { types } from '../../actions/quiz/types';
import { getTime } from 'date-fns';

const useStyles = () => ({
  root: {
    display: 'flex',
    marginTop: 10
  },
  timer: {
    fontSize: 40,
    position: 'absolute',
    top: 20,
    left: 20,
    color: 'red'
  },
  colMain: {
    flexGrow: 1,
  },
  colSide: {
    padding: '50px 10px 0',
  },
  buttonOverview: {
    fontWeight: 'bold',
    margin: '0 7px 7px 0',
  },
  isActive: {
    bottom: '3px',
  },
  isAnswered: {
    backgroundColor: props => props.themeColorPrimary.main,
    color: props => props.themeColorPrimary.contrastText,
  },
  endQuizContainer: {
    padding: 15,
  },
  buttonEnd: {
    fontWeight: 'bold',
    width: '100%',
  },
  overviewContainer: {
    marginTop: 30
  },
});

const Quiztake = ({ classes, quizData, pageId,
                    handleQuizAnswer, handleQuizComplete,
                    startDate, timer, isTimer }) => {
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const isLastQuestion = activeIndex === quizData.length - 1;
  const { messages } = useIntl();
  const millisecondsFormat = timer && timer.split(':').reduce((acc,time) => (60 * acc) + +time) * 1000;
  const countDownRef = useRef();

  const handlePaginationClick = (isNext) => {
    const newQuestionIndex = isNext ? activeIndex + 1 : activeIndex - 1;

    setActiveIndex(newQuestionIndex);
  }

  const handleAnswer = (answers) => {
    const { taskId, pageModuleId: pmid } = quizData[activeIndex];
    const body = { value: answers };
    const data = { taskId, pmid, body };

    handleQuizAnswer(data);

    if (!isLastQuestion) setActiveIndex(activeIndex + 1);
  }

  const handleOverviewClick = (taskId) => {
    const clickedQuestionIndex = quizData.findIndex(q => q.taskId === taskId);
    setActiveIndex(clickedQuestionIndex);
  }

  const renderQuestion = () => {
    const { type, task, taskResults, answered } = quizData[activeIndex];
    const isSingle = type === 1; // "type: 1" = single (radio), "type: 2" = multi (checkbox)
    const prevAnswer = answered ? taskResults[taskResults.length - 1] : null;

    return isSingle ? (
      <QuestionSingle
        task={task}
        key={task.taskId}
        prevAnswer={prevAnswer}
        onAnswer={handleAnswer}
      />
    ) : (
      <QuestionMulti
        task={task}
        key={task.taskId}
        prevAnswer={prevAnswer}
        onAnswer={handleAnswer}
      />
    );
  }

  const renderEndButton = () => {
    const isAllQuestionsAnswered = quizData.every((q) => q.answered === true);

    return (
      <div className={classes.endQuizContainer}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.buttonEnd}
          disabled={!isAllQuestionsAnswered}
          onClick={() => handleQuizComplete(pageId)}
        >
          {messages.quiz.buttonEnd}
        </Button>
      </div>
    )
  }

  const renderOverview = () => (
    quizData.map((q, i) => {
      const { taskId, answered } = q;
      const isActive = taskId === quizData[activeIndex].taskId;
      const buttonClasses = `${classes.buttonOverview} ${isActive ? classes.isActive : null} ${answered ? classes.isAnswered : null}`;

      return (
        <Button
          key={taskId}
          onClick={() => handleOverviewClick(taskId)}
          variant="contained"
          color="default"
          className={buttonClasses}
        >
          {i + 1}
        </Button>
      );
    })
  );

  const renderer = ({ hours, minutes, seconds, total }) => {
    if (total === -1000) {
      alert('Time is out. Your progress will be saved.');
      return null;
    } else {
      return <span className={classes.timer}>
        {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
      </span>;
    }
  };

  const countDownDate = () => {
    if (startDate !== +new Date()) {
      return getTime(Date.now()) + millisecondsFormat;
    } else {
      return startDate + millisecondsFormat;
    }
  }

  const checkIfOneAnswered = () => {
    const ifOneAnswered = quizData.some(el => el.answered)
    if (ifOneAnswered) {
      handleQuizComplete(pageId)
    } else {
      dispatch({ type: types.QUIZ_TIMEOUT })
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.colSide}>
        <PaginationButton
          isDisabled={activeIndex === 0}
          handleClick={() => handlePaginationClick(false)}
        >
          <ArrowBackRoundedIcon style={{ fontSize: 22 }} />
        </PaginationButton>
      </div>

      <div className={classes.colMain}>
        {renderQuestion()}
        {renderEndButton()}

        {isTimer && <div>
          <Countdown
            renderer={renderer}
            date={countDownDate()}
            overtime
            ref={countDownRef}
            onTick={(e) => {
              if (e.total === -1000) {
                countDownRef.current.api.stop()
              }
            }}
            onStop={() => checkIfOneAnswered()}
          />
        </div>}

        <div className={classes.overviewContainer}>
          <hr/>
          {renderOverview()}
        </div>
      </div>

      <div className={classes.colSide}>
        <PaginationButton
          isDisabled={isLastQuestion}
          handleClick={() => handlePaginationClick(true)}
        >
          <ArrowForwardRoundedIcon style={{ fontSize: 22 }} />
        </PaginationButton>
      </div>
    </div>
  );
};


export default withStyles(useStyles)(Quiztake);
