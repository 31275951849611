import React from 'react';
import { useIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz'

const useStyles = () => ({
  root: {
    textAlign: 'center',
  },
  questionContainer: {
    margin: '35px 15px',
    fontSize: '14px',
    textAlign: 'left',
  },
  answersContainer: {
    backgroundColor: '#e0e0e0',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 15,
    marginTop: 15,
  },
  answerInfo: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginLeft: '15px',
  },
  answerInfoParagraph: {
    color: '#aaa',
    fontSize: '12px',
    fontWeight: 'bold',
    margin: 0,
    textTransform: 'uppercase',
  },
  date: {
    marginBottom: 15,
  },
  question: {
    fontWeight: 600,
    margin: 0,
  },
  answerRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },
  answer: {
    margin: 0,
  },
  iconGreen: {
    color: '#00732f',
    fontSize: '20px',
    marginRight: 10,
  },
  iconRed: {
    color: '#dc1500',
    fontSize: '20px',
    marginRight: 10,
  },
  button: {
    fontWeight: 'bold',
  },
});

const ResultView = ({
  classes,
  quizData,
  results,
  settings,
  isUserOnStartview,
  handleQuizStart,
}) => {
  const { messages } = useIntl();

  const calcScore = () => (
    quizData
      .map(q => q.taskResults[q.taskResults.length - 1]
        && q.taskResults[q.taskResults.length - 1].points)
      .reduce((acc, currentVal) => acc + currentVal)
  );

  const score = results ? results.score : calcScore() ? calcScore() : 0;
  const totalScore = quizData.length;
  const scoreString = `${score}/${totalScore}`;
  const threshold = settings.threshold / 100;
  const neededScore = totalScore * (threshold || 0.8);
  const didUserPass = (score >= neededScore);
  const message = didUserPass ? settings.successText : settings.failText;

  const renderIntro = () => {
    if (isUserOnStartview) {
      return <h3>{`${messages.quiz.prevResultIntro} (${messages.quiz.score}: ${scoreString})`}</h3>;
    }

    return (
      <div>
        <h2>{message}</h2>
        <p>Your score: <strong>{score}/{totalScore}</strong></p>
      </div>
    );
  }

  const renderAnswers = () => {
    if (settings.hideAnswers) return null;

    return quizData.map(q => {
      const isSingle = q.task.type === 1;
      const question = q.task.caption.main;
      const allAnswers = q.task.caption.items;
      const correctAnswersIndexes = q.task.correctResponse.value;
      const lastAnswers = q.taskResults[q.taskResults.length - 1];
      if (q.taskResults.length === 0) {
        return null;
      }
      const lastAnswersIndexes = lastAnswers.body.value;
      const lastAnswersDate = zonedTimeToUtc(lastAnswers.created, 'Central European Time');
      const lastAnswersDateFormatted = format(new Date(lastAnswersDate), 'yyyy-MM-dd HH:mm');

      const answerRows = correctAnswersIndexes.map(correctAnswerIndex => {
        const isThisAnswerCorrect = lastAnswersIndexes.includes(correctAnswerIndex);
        let answerValue = isSingle ? allAnswers[lastAnswersIndexes[0]] : allAnswers[correctAnswerIndex];

        if (!isSingle && !isThisAnswerCorrect) {
          answerValue = answerValue + ' (MISSED)';
        }

        return (
          <div key={correctAnswerIndex} className={classes.answerRow}>
            {isThisAnswerCorrect
              ? <CheckCircleIcon className={classes.iconGreen} />
              : <CancelIcon className={classes.iconRed} />
            }

            <p className={classes.answer}>
              {answerValue}
            </p>
          </div>
        );
      });

      return (
        <div key={q.taskId} className={classes.questionContainer}>
          <p className={classes.question}>{question}</p>

          <Paper
            elevation={0}
            className={classes.answersContainer}
          >
            <div>
              {answerRows}
            </div>

            <div className={classes.answerInfo}>
              <p className={`${classes.date} ${classes.answerInfoParagraph}`}>
                {lastAnswersDateFormatted}
              </p>

              <p className={classes.answerInfoParagraph}>
                {messages.quiz.points}: {lastAnswers.points}/1
              </p>
            </div>
          </Paper>
        </div>
      )
    });
  }

  return (
    <div className={classes.root}>
      { renderIntro() }
      { renderAnswers() }

      {handleQuizStart &&
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleQuizStart}
        >
          {messages.quiz.buttonRetake}
        </Button>
      }
    </div>
  );
};

export default withStyles(useStyles)(ResultView);
